import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { logoutUser } from '../../services/auth';

export default class Header extends Component {
	handleLogout() {
		logoutUser();
	}

	render() {
		return (
			<div>
				{/* Navbar */}
				<nav className="main-header navbar navbar-expand navbar-white navbar-light">
					{/* Left navbar links */}
					<ul className="navbar-nav">
						<li className="nav-item">
							<Link
								className="nav-link"
								data-widget="pushmenu"
								role="button"
								to={'/pnl/dash'}
							>
								<i className="fas fa-bars" />
							</Link>
						</li>
						<li className="nav-item d-none d-sm-inline-block">
							<Link className="nav-link" to={'/pnl/dash'}>
								Home
							</Link>
						</li>
					</ul>

					{/* Right navbar links */}
					<ul className="navbar-nav ml-auto">
						<li className="nav-item">
							<Link
								type="button"
								className="btn btn-outline-dark btn-sm"
								to={'/'}
								onClick={() => this.handleLogout()}
							>
								<i className="fas fa-power-off" /> Exit
							</Link>
						</li>
					</ul>
				</nav>
				{/* /.navbar */}
			</div>
		);
	}
}
