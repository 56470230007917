import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './components/app_router/appRouter';
import registerServiceWorker from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';

import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
import 'admin-lte/dist/css/adminlte.min.css';

import 'admin-lte/plugins/jquery/jquery.min.js';
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js';
import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js';
import 'admin-lte/dist/js/adminlte.js';

import 'admin-lte/plugins/jquery-mousewheel/jquery.mousewheel.js';
import 'admin-lte/plugins/raphael/raphael.min.js';
import 'admin-lte/plugins/jquery-mapael/jquery.mapael.min.js';
import 'admin-lte/plugins/jquery-mapael/maps/usa_states.min.js';
import 'admin-lte/plugins/chart.js/Chart.min.js';

ReactDOM.render(
	<React.StrictMode>
		<AppRouter />
	</React.StrictMode>,
	document.getElementById('root')
);
//registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
