import * as moment from 'moment';

export function imgixUrl(imageUrl) {
	if (imageUrl != null && imageUrl.includes('auto=compress')) {
		if (imageUrl.includes('&w=720')) {
			return imageUrl.replace('&w=720', '&w=250');
		} else {
			return imageUrl + '&w=250';
		}
	}
	return imageUrl + '?w=100';
}

export function getBase64ImageFromURL(url) {
	return new Promise((resolve, reject) => {
		var img = new Image();
		img.setAttribute('crossOrigin', 'anonymous');
		img.onload = () => {
			var canvas = document.createElement('canvas');
			canvas.width = img.width;
			canvas.height = img.height;
			var ctx = canvas.getContext('2d');
			ctx.drawImage(img, 0, 0);
			var dataURL = canvas.toDataURL('image/png');
			resolve(dataURL);
		};
		img.onerror = (error) => {
			reject(error);
		};
		img.src = url;
	});
}

export function formatDate(timestamp) {
	return moment(timestamp).format('DD.MM.YYYY HH:mm');
}

export function formatDateDate(timestamp) {
	return moment(timestamp).format('DD.MM.YYYY');
}

export function formatDateWoTime(timestamp) {
	return moment(timestamp).format('YYYY-MM-DD');
}

export function formatTime(timestamp) {
	return moment(timestamp).format('HH:mm');
}

export function removeFromList(list, el) {
	for (let i = 0; i < list.length; i++) {
		if (list[i] === el) {
			list.splice(i, 1);
		}
	}
	return list;
}
