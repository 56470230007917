import React, { Component } from 'react';
import qs from 'qs';
import {
	fetchScheduledEvents,
	createScheduledEvent,
	patchScheduledEvent,
	deleteScheduledEvent,
} from '../../services/bookings';
import { fetchUserCars, fetchCar } from '../../services/cars';
import { toast } from 'react-toastify';
import Select from 'react-select';
import * as moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const currentUser = JSON.parse(localStorage.getItem('user'));

export default class PopupSC extends Component {
	constructor(props) {
		super(props);
		this.state = {
			event: this.props?.event?.extendedProps || '',
			carInput: '',
			phoneInput: this.props?.event?.extendedProps.renter.phone || '',
			purposeInput: this.props?.event?.extendedProps.travelPurpose || '',
			fromDateTime: this.props?.event
				? new Date(this.props?.event?.extendedProps.from)
				: new Date(),
			toDateTime: this.props?.event
				? new Date(this.props?.event?.extendedProps.to)
				: new Date(),
			carList: [],
			showPopup: false,
			showModal: false,
		};

		this.refreshData = this.refreshData.bind(this);
	}

	componentDidMount() {
		this.refreshData();
	}

	refreshData(state, instance) {
		// show the loading overlay
		this.setState({ loading: true });
		let query = {
			// pageSize: state.pageSize,
			// page: state.page,
			type: 'CAR',
		};

		fetchUserCars(`?${qs.stringify(query)}`, currentUser.id).then((cars) => {
			this.setState({
				carList: cars,
				loading: false,
			});
		});
		if (this.props?.event?.extendedProps.vehicle) {
			fetchCar(this.props?.event?.extendedProps.vehicle.id).then((car) => {
				let carData = { ...car };
				this.setState({ carInput: carData });
			});
		}
	}

	handleChangePhoneField(e, field) {
		if (e.value) {
			let phone = this.state?.phoneInput?.trim().replace(/\D/g, '');
			phone = e?.value;
			this.setState({ phoneInput: phone });
		}
	}

	handleChangeCarField(e, field) {
		if (e.value) {
			fetchCar(e.value).then((car) => {
				let carData = { ...car };
				this.setState({ carInput: carData });
			});
		}
	}

	handleChangePurposeField(e, field) {
		let purpose = this.state.purposeInput;
		purpose = e.target.value;
		this.setState({ purposeInput: purpose });
	}

	handleCreateScheduledEvent(renterCar, renterPhone, timeFrom, timeTo, textPurpose) {
		// if (
		// 	renterPhone.trim() !== '' &&
		// 	renterPhone.length > 6 &&
		// 	moment().diff(timeFrom, 'minutes') < 1 &&
		// 	moment(timeTo).diff(timeFrom, 'minutes') >= 30
		// )
		if (renterPhone.trim() !== '' && renterPhone.length > 6) {
			const newRenterPhone = `+${renterPhone.trim().replace(/\D/g, '')}`;
			createScheduledEvent(renterCar.id, {
				phone: newRenterPhone,
				scheduledFrom: moment(timeFrom).valueOf(),
				scheduledTo: moment(timeTo).valueOf(),
				purpose: textPurpose,
			})
				.then((res) => {
					toast.success('Created successfully!');
					window.location.reload();
				})
				.catch((err) => {
					toast.error(err.response);
				});
		} else {
			// if (!(moment().diff(timeFrom, 'minutes') < 1)) {
			// 	toast.error(`Start time must be ${formatDate(moment().valueOf())} or more!`);
			// } else if (!(moment(timeTo).diff(timeFrom, 'minutes') > 30)) {
			// 	toast.error('Travel duration has to be at least 30min!');
			// } else {
			// 	toast.error('You have to input correct phone number & time of reservation!');
			// }
			toast.error('You have to input correct phone number & time of reservation!');
		}
	}

	handleEditScheduledEvent(eventId, renterCar, renterPhone, timeFrom, timeTo, textPurpose) {
		timeFrom = moment(timeFrom?.date || timeFrom).valueOf();
		timeTo = moment(timeTo?.date || timeTo).valueOf();

		// if (
		// 	renterPhone.trim() !== '' &&
		// 	renterPhone.length > 6 &&
		// 	moment().diff(timeFrom, 'minutes') < 1 &&
		// 	moment(timeTo).diff(timeFrom, 'minutes') >= 30
		// )
		if (renterPhone.trim() !== '' && renterPhone.length > 6) {
			const newRenterPhone = `+${renterPhone.trim().replace(/\D/g, '')}`;
			patchScheduledEvent(eventId, {
				vehicleId: renterCar.id,
				phone: newRenterPhone,
				scheduledFrom: moment(timeFrom).valueOf(),
				scheduledTo: moment(timeTo).valueOf(),
				purpose: textPurpose,
			})
				.then((res) => {
					toast.success('Changed successfully!');
					window.location.reload();
				})
				.catch((err) => {
					toast.error(err.response);
				});
		} else {
			// if (!(moment().diff(timeFrom, 'minutes') < 1)) {
			// 	toast.error(`Start time must be ${formatDate(moment().valueOf())} or more!`);
			// } else if (!(moment(timeTo).diff(timeFrom, 'minutes') > 30)) {
			// 	toast.error('Travel duration has to be at least 30min!');
			// } else {
			// 	toast.error('You have to input correct phone number & time of reservation!');
			// }
			toast.error('You have to input correct phone number & time of reservation!');
		}
	}

	handleDelScheduledEvent(eventId) {
		deleteScheduledEvent(eventId)
			.then((res) => {
				toast.success('Deleted successfully!');
				window.location.reload();
			})
			.catch((err) => {
				toast.error(err.response);
			});
	}

	render() {
		const eventId = this.props?.event?.extendedProps?.id;
		return (
			<div className="card-body">
				<div className="form-group">
					<label className="col-form-label">Purpose Of Travel:</label>
					<input
						type="text"
						className="form-control"
						value={this.state.purposeInput}
						placeholder={'Vnesite namen poti...'}
						onChange={(e) => this.handleChangePurposeField(e, 'purposeInput')}
					/>
				</div>

				<hr />
				<div className="row">
					<div className="col-12 col-sm-6">
						<div className="form-group">
							<label className="col-form-label">Select Vehicle:</label>
							<Select
								className="selector"
								defaultValue={{
									value: this.props?.event
										? this.props?.event?.extendedProps.vehicle.id
										: null,
									label: this.props?.event
										? `${this.props?.event?.extendedProps.vehicle.type} ${this.props?.event?.extendedProps.vehicle.IMEI}`
										: 'Select...',
								}}
								isDisabled={false}
								isLoading={false}
								isClearable={false}
								isRtl={false}
								isSearchable={true}
								options={this.state?.carList.map((el) => ({
									value: el.id,
									label: `${el.type} ${el.IMEI}`,
								}))}
								onChange={(e) => this.handleChangeCarField(e, 'carId')}
							/>
						</div>
					</div>
					<div className="col-12 col-sm-6">
						<div className="form-group">
							<label className="col-form-label">Select Users Phone:</label>
							<Select
								className="selector"
								defaultValue={{
									value: this.props?.event
										? this.props?.event?.extendedProps.renter.phone
										: null,
									label: this.props?.event
										? `${this.props?.event?.extendedProps.renter.firstName} ${this.props?.event?.extendedProps.renter.lastName}`
										: 'Select...',
								}}
								isDisabled={false}
								isLoading={false}
								isClearable={false}
								isRtl={false}
								isSearchable={true}
								options={this.state?.carInput?.private?.allowedUsers.map((el) => ({
									value: el.phone,
									label: `${el.firstName} ${el.lastName}`,
								}))}
								onChange={(e) => this.handleChangePhoneField(e, 'phoneInput')}
							/>
						</div>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col-12 col-sm-6">
						<div className="card card-outline card-primary">
							<div className="card-header">
								<h5 className="card-title">Choose Start Time:</h5>
							</div>
							{/* /.card-header */}
							<div className="card-body" style={{ display: 'block' }}>
								<TextField
									id="datetime-local"
									//label="Select Start Time"
									type="datetime-local"
									defaultValue={moment(this.state.fromDateTime).format(
										'YYYY-MM-DDTHH:mm'
									)}
									onChange={(e) => {
										this.setState({
											fromDateTime: moment(e.target.value).valueOf(),
										});
									}}
									className={makeStyles((theme) => ({
										container: {
											display: 'flex',
											flexWrap: 'wrap',
										},
										textField: {
											marginLeft: theme.spacing(1),
											marginRight: theme.spacing(1),
											width: 200,
										},
									}))}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-6">
						<div className="card card-outline card-primary">
							<div className="card-header">
								<h5 className="card-title">Choose End Time:</h5>
							</div>
							{/* /.card-header */}
							<div className="card-body" style={{ display: 'block' }}>
								<TextField
									id="datetime-local"
									//label="Select End Time"
									type="datetime-local"
									defaultValue={moment(this.state.toDateTime).format(
										'YYYY-MM-DDTHH:mm'
									)}
									onChange={(e) => {
										this.setState({
											toDateTime: moment(e.target.value).valueOf(),
										});
									}}
									className={makeStyles((theme) => ({
										container: {
											display: 'flex',
											flexWrap: 'wrap',
										},
										textField: {
											marginLeft: theme.spacing(1),
											marginRight: theme.spacing(1),
											width: 200,
										},
									}))}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				{this.props?.event && (
					<button
						className="btn btn-danger"
						onClick={() => this.handleDelScheduledEvent(eventId)}
					>
						Delete Reservation
					</button>
				)}

				<hr />

				{this.props?.event && (
					<button
						className="btn btn-success"
						onClick={() =>
							this.handleEditScheduledEvent(
								eventId,
								this.state.carInput,
								this.state.phoneInput,
								this.state.fromDateTime,
								this.state.toDateTime,
								this.state.purposeInput
							)
						}
					>
						Save Reservation
					</button>
				)}

				{!this.props?.event && (
					<button
						className="btn btn-success"
						onClick={() =>
							this.handleCreateScheduledEvent(
								this.state.carInput,
								this.state.phoneInput,
								this.state.fromDateTime,
								this.state.toDateTime,
								this.state.purposeInput
							)
						}
					>
						Create Reservation
					</button>
				)}
			</div>
		);
	}
}

PopupSC.propTypes = {
	event: PropTypes.any,
};
