import React, { Component } from 'react';
import { login, signin, logoutUser } from '../../services/auth';
import { toast } from 'react-toastify';
import backSVG from '../../assets/background.svg';

export default class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			showWrongEmailOrPasswordError: false,
			showEmailRequiredError: false,
			showPasswordRequiredError: false,
			smsWasSent: false,
		};
	}

	handleSignin() {
		if (!this.state.email) return;
		signin(this.state.email)
			.then((res) => {
				this.setState({ smsWasSent: true });
			})
			.catch((err) => {
				toast.error(err.response);
				if (!err.response) {
					console.log('No connection');
					return;
				}
				if (err.response.status) {
					this.setState({ showWrongEmailOrPasswordError: true });
				}
			});
	}

	handleLogin() {
		if (!this.state.email || !this.state.password) return;
		login(this.state.email, this.state.password)
			.then((res) => {
				if (
					res?.data?.roleMB !== 'ROOT' &&
					res?.data?.roleMB !== 'ADMIN' &&
					res?.data?.roleMB !== 'SUPPORT' &&
					res?.data?.roleMB !== 'MANAGER' &&
					res.data.roleMB !== 'SERVICE'
				) {
					logoutUser();
					this.setState({ showWrongEmailOrPasswordError: true });
					return;
				}
				localStorage.setItem('accessToken', res.headers['accesstoken']);
				localStorage.setItem(
					'user',
					JSON.stringify({
						id: res.data.id,
						role: res.data.roleMB,
						email: res.data.email,
						firstName: res.data.firstName,
						lastName: res.data.lastName,
					})
				);

				// eslint-disable-next-line react/prop-types
				this.props.history.push(`/pnl/dash`);
			})
			.catch((err) => {
				if (!err.response) {
					console.log('No connection');
					return;
				}
				if (err.response.status) {
					this.setState({ showWrongEmailOrPasswordError: true });
				}
			});
	}

	handleEmailChange(e) {
		this.setState(
			{
				showWrongEmailOrPasswordError: false,
				showEmailRequiredError: false,
				email: e.target.value,
			},
			() => {
				if (!this.state.email) {
					this.setState({ showEmailRequiredError: true });
				}
			}
		);
	}

	handlePasswordChange(e) {
		this.setState(
			{
				showWrongEmailOrPasswordError: false,
				showPasswordRequiredError: false,
				password: e.target.value,
			},
			() => {
				if (!this.state.password) {
					this.setState({ showPasswordRequiredError: true });
				}
			}
		);
	}

	handlePasswordKeyDown(e) {
		if (e.key === 'Enter') {
			this.handleLogin();
		}
	}

	render() {
		return (
			<div className="login">
				<div
					className="hold-transition login-page"
					style={{
						backgroundImage: `url(${backSVG})`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'bottom center',
					}}
				>
					<div className="login-box">
						{/* <div className="login-logo">
							<b>GiroMobility</b> Dashboard
						</div> */}
						<div className="card">
							<div className="card-body login-card-body">
								<p className="login-box-msg">Sign in to start your session</p>
								<div className="input-group mb-3">
									<input
										type="email"
										className="form-control"
										placeholder="E-mail"
										onChange={(e) => this.handleEmailChange(e)}
										value={this.state.email}
									/>
									<div className="input-group-append">
										<div className="input-group-text">
											<span className="fas fa-envelope" />
										</div>
									</div>
								</div>
								{this.state.showPasswordRequiredError && (
									<span className="info-text">Code is required</span>
								)}
								{this.state.smsWasSent && (
									<div className="input-group mb-3">
										<input
											type="password"
											className="form-control"
											placeholder="SMS Code"
											onChange={(e) => this.handlePasswordChange(e)}
											onKeyDown={(e) => this.handlePasswordKeyDown(e)}
											value={this.state.password}
										/>
										<div className="input-group-append">
											<div className="input-group-text">
												<span className="fas fa-lock" />
											</div>
										</div>
									</div>
								)}
								{!this.state.smsWasSent && (
									<div className="row">
										<button
											className="btn btn-primary btn-block"
											onClick={() => this.handleSignin()}
										>
											Sign In
										</button>
									</div>
								)}
								{this.state.smsWasSent && (
									<div className="row">
										<button
											className="btn btn-primary btn-block"
											onClick={() => this.handleLogin()}
										>
											Submit Code
										</button>
									</div>
								)}
								{this.state.showWrongEmailOrPasswordError && (
									<div className="error-msg">
										<span>Wrong email or code</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
