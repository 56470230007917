import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import { withRouter } from 'react-router-dom';
import { fetchUsers, fetchUsersCount } from '../../services/users';
import qs from 'qs';
import * as _ from 'lodash';
import { formatDate } from '../../utils/utils';
import ModalUS from './modal';

class UsersTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			columns: [],
			loading: true,
			pages: -1,
			chosenUser: {},
		};

		this.refreshData = _.debounce(this.refreshData.bind(this), 300);
	}

	componentDidMount() {
		fetchUsersCount().then((count) => this.setState({ pages: Math.ceil(count / 15) }));
	}

	getColumns() {
		return [
			{
				id: 'edit',
				Header: <i className="fas fa-external-link-square-alt fa-lg" />,
				accessor: (d) => d,
				Cell: ({ value }) => {
					return (
						<button className="btn btn-default" style={{ margin: 2 }}>
							<i className="fas fa-search-plus fa-lg" />
						</button>
					);
				},
				headerStyle: { textAlign: 'center' },
				style: { padding: 2, margin: 0 },
				maxWidth: 60,
				resizable: false,
				sortable: false,
				filterable: false,
			},
			{
				Header: 'Phone Number',
				accessor: 'phone',
				style: { fontWeight: 'bold', textAlign: 'center' },
				maxWidth: 200,
				sortable: false,
			},
			{
				Header: 'E-mail',
				accessor: 'email',
				style: { fontWeight: 'bold' },
				maxWidth: 300,
				sortable: false,
			},
			{
				id: 'name',
				Header: 'Name',
				maxWidth: 500,
				resizable: false,
				style: { textAlign: 'center' },
				accessor: (d) => `${d.firstName || '/'} ${d.lastName || '/'}`,
				sortable: false,
				filterable: false,
			},
			{
				Header: 'Status',
				style: { textAlign: 'center', fontWeight: 'bold' },
				accessor: 'statusMB',
				Filter: ({ filter, onChange }) => (
					<select
						onChange={(event) => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : 'all'}
					>
						<option value="">Show all</option>
						<option value="BANNED">BANNED</option>
						<option value="PENDING">PENDING</option>
						<option value="VERIFIED">VERIFIED</option>
					</select>
				),
				maxWidth: 150,
				resizable: false,
				sortable: false,
			},
			{
				id: 'createdAt',
				Header: 'Created Date & Time',
				style: { textAlign: 'center' },
				accessor: (d) => formatDate(d.createdAt),
				maxWidth: 160,
				resizable: false,
				filterable: false,
			},
		];
	}

	getRows() {
		return this.state.users;
	}

	setModalShow(show) {
		if (show === false) {
			this.setState({ chosenUser: {} });
		}
		this.setState({ showModal: show });
	}

	handleClickOnEdit(rowInfo) {
		return {
			onClick: () => {
				this.setState({ chosenUser: rowInfo?.original });
				this.setModalShow(true);
			},
		};
	}

	refreshData(state) {
		// show the loading overlay
		this.setState({ loading: true });
		let query = {
			pageSize: state.pageSize,
			page: state.page,
		};
		if (state.sorted.length) {
			query['sortField'] = state.sorted[0].id;
			query['sortOrder'] = state.sorted[0].desc ? 'DESC' : 'ASC';
		}
		state.filtered.forEach((filter) => {
			if (filter.id === 'phone') {
				filter.value = filter.value.replace(/[^0-9]/g, '');
			}
			query[filter.id] = filter.value;
		});
		fetchUsers(`?${qs.stringify(query)}`).then((users) => {
			this.setState({ users: users, loading: false });
		});
	}

	getTrProps = (state, rowInfo, instance) => {
		if (rowInfo) {
			if (rowInfo.row.statusMB === 'VERIFIED' && !!rowInfo.row.phone && !!rowInfo.row.email) {
				return {
					style: {
						background: 'rgba(124,204,99,0.8)',
					},
				};
			} else if (
				rowInfo.row.statusMB === 'VERIFIED' &&
				(!rowInfo.row.phone || !rowInfo.row.email)
			) {
				return {
					style: {
						background: 'rgba(104,204,202,0.5)',
					},
				};
			} else if (rowInfo.row.statusMB === 'BANNED') {
				return {
					style: {
						background: 'rgba(244,78,59,0.5)',
					},
				};
			} else {
				return {};
			}
		}
		return {};
	};

	render() {
		let columns = this.getColumns();
		let rows = this.getRows();
		return (
			<div>
				<ReactTable
					className="-highlight"
					data={rows}
					pages={this.state.pages}
					columns={columns}
					getTrProps={this.getTrProps}
					getTdProps={(state, rowInfo, column, instance) => {
						if (!!column && column.id === 'edit') {
							return this.handleClickOnEdit(rowInfo);
						} else return {};
					}}
					//data={this.state.data} // should default to []
					//pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
					loading={this.state.loading}
					manual={true} // informs React Table that you'll be handling sorting and pagination server-side
					onFetchData={this.refreshData}
					showPageSizeOptions={false}
					defaultPageSize={15}
					multiSort={false}
					filterable
					defaultSorted={[{ id: 'createdAt', desc: true }]}
				/>
				<ModalUS
					show={this.state.showModal}
					onHide={() => this.setModalShow(false)}
					user={this.state.chosenUser}
				/>
			</div>
		);
	}
}

export default withRouter(UsersTable);
