/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import * as _ from 'lodash';
import { fetchBookings, fetchBookingsCount } from '../../services/bookings';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import { formatDate } from '../../utils/utils';
import ModalBC from './modal';

const currentUser = JSON.parse(localStorage.getItem('user'));

class BookingsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			columns: [],
			loading: true,
			pages: -1,
			chosenBooking: {},
		};

		this.refreshData = _.debounce(this.refreshData.bind(this), 300);
	}

	componentDidMount() {
		fetchBookingsCount().then((count) => {
			this.setState({ pages: Math.ceil(count / 15) });
		});
	}

	getColumns() {
		return [
			{
				id: 'edit',
				Header: <i className="fas fa-edit fa-lg" />,
				accessor: (d) => d,
				Cell: ({ value }) => {
					return (
						<button className="btn btn-default" style={{ margin: 2 }}>
							<i className="fas fa-search-plus fa-lg" />
						</button>
					);
				},
				headerStyle: { textAlign: 'center' },
				style: { padding: 2, margin: 0 },
				maxWidth: 60,
				resizable: false,
				sortable: false,
				filterable: false,
			},
			{
				id: 'code',
				Header: 'Code',
				maxWidth: 150,
				style: { textAlign: 'center', fontWeight: 'bold' },
				resizable: false,
				accessor: (d) => d.code,
			},
			{
				id: 'renter',
				Header: 'Renter',
				maxWidth: 500,
				resizable: false,
				style: { textAlign: 'center' },
				accessor: (d) =>
					currentUser?.role &&
					_.indexOf(['ROOT', 'ADMIN', 'SUPPORT'], currentUser?.role) > -1
						? `${d.renter?.firstName || '/'} ${d.renter?.lastName || '/'}`
						: (d.renter?.firstName
								? `${d.renter?.firstName.charAt(0) + '*****'}`
								: '/') +
						  '  ' +
						  (d.renter?.lastName ? `${d.renter?.lastName.charAt(0) + '*****'}` : '/'),
				sortable: false,
				filterable: false,
			},
			{
				id: 'imei',
				Header: 'Vehicle',
				maxWidth: 150,
				style: { textAlign: 'center', fontWeight: 'bold' },
				resizable: false,
				accessor: (d) => d.vehicle.IMEI,
			},
			{
				id: 'from',
				Header: 'Start Date',
				maxWidth: 160,
				resizable: false,
				accessor: (d) => formatDate(d.from),
				filterable: false,
			},
			{
				id: 'to',
				Header: 'End Date',
				maxWidth: 160,
				resizable: false,
				accessor: (d) => formatDate(d.to),
				filterable: false,
			},
			{
				id: 'endPrice',
				Header: 'End Price',
				maxWidth: 100,
				style: { textAlign: 'center', fontWeight: 'bold' },
				resizable: false,
				accessor: (d) => `${d.endPrice || 0} €`,
				filterable: false,
				sortable: false,
			},
			{
				id: 'endTime',
				Header: 'Duration',
				maxWidth: 100,
				style: { textAlign: 'center', fontWeight: 'bold' },
				resizable: false,
				accessor: (d) => `${d.endTime || 0} min`,
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Status',
				accessor: 'status',
				style: { textAlign: 'center', fontWeight: 'bold' },
				maxWidth: 150,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={(event) => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : 'all'}
					>
						<option value="">Show all</option>
						<option value="DRIVING">DRIVING</option>
						<option value="FINISHED">FINISHED</option>
					</select>
				),
				resizable: false,
				sortable: false,
			},
			{
				id: 'createdAt',
				Header: 'Created Date & Time',
				style: { textAlign: 'center' },
				accessor: (d) => formatDate(d.createdAt),
				maxWidth: 160,
				resizable: false,
				filterable: false,
			},
		];
	}

	getRows() {
		return this.state.bookings;
	}

	setModalShow(show) {
		if (show === false) {
			this.setState({ chosenVehicle: {} });
		}
		this.setState({ showModal: show });
	}

	handleClickOnEdit(rowInfo) {
		return {
			onClick: () => {
				this.setState({ chosenBooking: rowInfo?.original });
				this.setModalShow(true);
			},
		};
	}

	getTrProps = (state, rowInfo, instance) => {
		if (rowInfo) {
			switch (rowInfo.row.status) {
				case 'PENDING':
					return {
						style: {
							background: 'rgba(11,178,212,0.5)',
						},
					};
				case 'DRIVING':
					return {
						style: {
							background: 'rgba(62, 142, 247,0.8)',
						},
					};
				case 'FINISHED':
					return {
						style: {
							background: 'rgba(124,204,99,0.8)',
						},
					};
				case 'REFUNDED':
					return {
						style: {
							background: 'rgba(38,25,38,0.5)',
						},
					};
				default:
					return {};
			}
		}
		return {};
	};

	refreshData(state, instance) {
		// show the loading overlay
		this.setState({ loading: true });
		let query = {
			pageSize: state.pageSize,
			page: state.page,
		};
		if (state.sorted.length) {
			query['sortField'] = state.sorted[0].id;
			query['sortOrder'] = state.sorted[0].desc ? 'DESC' : 'ASC';
		}
		state.filtered.forEach((filter) => {
			if (filter.id === 'imei') {
				filter.value = filter.value.replace(/[^0-9]/g, '');
			}
			query[filter.id] = filter.value;
		});
		query['status'] = { $nin: ['SCHEDULED'] };
		fetchBookings(`?${qs.stringify(query)}`).then((bookings) => {
			this.setState({ bookings: bookings, loading: false });
		});
	}

	render() {
		let columns = this.getColumns();
		let rows = this.getRows();
		return (
			<div>
				<ReactTable
					className="-highlight"
					data={rows}
					pages={this.state.pages}
					columns={columns}
					getTrProps={this.getTrProps}
					getTdProps={(state, rowInfo, column, instance) => {
						if (!!column && column.id === 'edit') {
							return this.handleClickOnEdit(rowInfo);
						} else return {};
					}}
					// data={this.state.data} // should default to []
					// pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
					loading={this.state.loading}
					manual={true} // informs React Table that you'll be handling sorting and pagination server-side
					onFetchData={this.refreshData}
					showPageSizeOptions={false}
					defaultPageSize={15}
					multiSort={false}
					filterable
					defaultSorted={[{ id: 'from', desc: true }]}
				/>
				<ModalBC
					show={this.state.showModal}
					onHide={() => this.setModalShow(false)}
					booking={this.state.chosenBooking}
				/>
			</div>
		);
	}
}

export default withRouter(BookingsTable);
