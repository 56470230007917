import React, { Component } from 'react';
import logoSVG from '../../assets/logoWhite.svg';

export default class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: null,
		};
	}

	componentDidMount() {
		this.setState({
			currentUser: JSON.parse(localStorage.getItem('user')),
		});
	}

	render() {
		return (
			<div>
				{/* Main Sidebar Container */}
				<aside className="main-sidebar sidebar-dark-primary elevation-4">
					{/* Brand Logo */}
					<a href="/pnl/dash" className="brand-link">
						<span className="brand-text font-weight-light">
							<img src={`${logoSVG}`} alt="Giro Logo" className="brand-image" />
							<h4 style={{ color: 'whitesmoke' }}>
								<strong>Dash</strong>
							</h4>
						</span>
					</a>
					{/* Sidebar */}
					<div className="sidebar">
						{/* Sidebar user panel (optional) */}
						<div className="user-panel mt-3 pb-3 mb-3 d-flex">
							<div className="info">
								<h6 style={{ color: 'whitesmoke' }}>
									<strong>{`${this.state.currentUser?.role || ''}`}</strong>
								</h6>
								<h5 style={{ color: 'whitesmoke' }}>{`${
									this.state.currentUser?.firstName || ''
								} ${this.state.currentUser?.lastName || ''}`}</h5>
							</div>
						</div>
						{/* Sidebar Menu */}
						<nav className="mt-2">
							<ul
								className="nav nav-pills nav-sidebar flex-column"
								data-widget="treeview"
								role="menu"
								data-accordion="false"
							>
								<li className="nav-item">
									<a href="/pnl/dash" className="nav-link">
										<i className="nav-icon fas fa-tachometer-alt" />
										<p>Dashboard</p>
									</a>
								</li>
								{_.indexOf(
									['ROOT', 'ADMIN', 'SUPPORT', 'MANAGER', 'SERVICE'],
									this.state.currentUser?.role
								) > -1 && (
									<>
										<li className="nav-item">
											<a href="/pnl/vehicles" className="nav-link">
												<i className="nav-icon fas fa-car" />
												<p>Vehicles</p>
											</a>
										</li>

										<li className="nav-item">
											<a href="/pnl/map" className="nav-link">
												<i className="nav-icon fas fa-map" />
												<p>Show Map</p>
											</a>
										</li>
									</>
								)}
								{_.indexOf(
									['ROOT', 'ADMIN', 'MANAGER'],
									this.state.currentUser?.role
								) > -1 && (
									<>
										<li className="nav-item">
											<a href="/pnl/schedule" className="nav-link">
												<i className="nav-icon fas fa-calendar-alt" />
												<p>Reservations</p>
											</a>
										</li>

										<li className="nav-item">
											<a href="/pnl/rents" className="nav-link">
												<i className="nav-icon fas fa-road" />
												<p>Rents</p>
											</a>
										</li>
									</>
								)}

								{_.indexOf(
									['ROOT', 'ADMIN', 'SUPPORT'], //, 'MANAGER', 'SERVICE'
									this.state.currentUser?.role
								) > -1 && (
									<>
										<li className="nav-item">
											<a href="/pnl/reports" className="nav-link">
												<i className="nav-icon fas fa-tools" />
												<p>Reports</p>
											</a>
										</li>
									</>
								)}
								{_.indexOf(
									['ROOT', 'ADMIN', 'SUPPORT'],
									this.state.currentUser?.role
								) > -1 && (
									<>
										<li className="nav-item">
											<a href="/pnl/users" className="nav-link">
												<i className="nav-icon fas fa-users" />
												<p>Users</p>
											</a>
										</li>
									</>
								)}
							</ul>
						</nav>
					</div>
				</aside>
			</div>
		);
	}
}
