import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { logoutUser } from '../../services/auth';
import axios from 'axios';
import { isJwtTokenValid } from '../../utils/jwt';

export default class ProtectedRoute extends Component {
	constructor(props) {
		super(props);

		this.state = {
			invalidToken: false,
		};

		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (
					error?.response &&
					(error?.response?.status === 401 || error?.response?.status === 403)
				) {
					this.logoutUserAndRedirect();
				}
				return Promise.reject(error);
			}
		);

		this.logoutUserAndRedirect = this.logoutUserAndRedirect.bind(this);
	}

	componentDidMount() {
		let user = JSON.parse(localStorage.getItem('user'));
		if (!isJwtTokenValid() || !user) {
			this.logoutUserAndRedirect();
		}
	}

	logoutUserAndRedirect() {
		logoutUser();
		this.setState({ invalidToken: true });
	}

	render() {
		// eslint-disable-next-line react/prop-types
		const { component: Component } = this.props;

		return (
			<Route
				{...this.props}
				component={undefined}
				render={(matchProps) =>
					this.state.invalidToken ? (
						<Redirect to={{ pathname: '/' }} />
					) : (
						<Component {...matchProps} />
					)
				}
			/>
		);
	}
}
