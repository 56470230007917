import React, { Component } from 'react';
import BookingsTable from './bookingsTable';

export default class Bookings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bookings: [],
		};
	}

	componentDidMount() {}

	render() {
		return (
			<div>
				<div className="content-wrapper">
					<section className="content">
						<div className="container-fluid">
							<div className="col-sm-6">
								<h2 className="m-0 text-dark">Rents List</h2>
							</div>
							<BookingsTable />
						</div>
					</section>
				</div>
			</div>
		);
	}
}
