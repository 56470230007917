/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import PopupSC from './popup';

const ModalSC = ({ show = false, onHide, history, selectedEvent }) => (
	<Modal
		className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
		isOpen={show}
		onRequestClose={onHide}
		appElement={document.getElementById('root')}
	>
		<div className="modal-content">
			<div className="modal-header bg-gradient-gray">
				<h4 className="modal-title">{`Create Scheduled Reservation`}</h4>
				<button
					type="button"
					className="close"
					data-dismiss="modal"
					aria-label="Close"
					onClick={onHide}
				>
					<span aria-hidden="true">×</span>
				</button>
			</div>
			<div className="modal-body">
				<PopupSC history={history} event={selectedEvent} />
			</div>
			<div className="modal-footer justify-content-between">
				<button type="button" className="btn btn-default" onClick={onHide}>
					Close
				</button>
				{/* <button type="button" className="btn btn-primary">
					Save changes
				</button> */}
			</div>
		</div>
	</Modal>
);

ModalSC.propTypes = {
	show: PropTypes.bool,
	onHide: PropTypes.func,
};

export default ModalSC;
