import React, { Component } from 'react';
import * as _ from 'lodash';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { fetchUsersDash } from '../../services/users';

export default class Content extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			currentUser: JSON.parse(localStorage.getItem('user')),
			infodash: {},
		};

		this.refreshData = _.debounce(this.refreshData.bind(this), 300);
	}

	componentDidMount() {
		this.refreshData();
	}

	refreshData(state, instance) {
		// show the loading overlay
		this.setState({ loading: true });
		fetchUsersDash(this.state.currentUser.id).then((info) => {
			this.setState({ infodash: info, loading: false });
		});
	}

	render() {
		return (
			<div>
				{/* Content Wrapper. Contains page content */}
				<div className="content-wrapper">
					{/* Content Header (Page header) */}
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<div className="col-sm-6">
									<h1 className="m-0 text-dark">Dashboard</h1>
								</div>
								{/* /.col */}
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<li className="breadcrumb-item">
											<a href="/pnl/dash">Home</a>
										</li>
										<li className="breadcrumb-item active">Dashboard</li>
									</ol>
								</div>
								{/* /.col */}
							</div>
							{/* /.row */}
						</div>
						{/* /.container-fluid */}
					</div>
					{/* /.content-header */}
					{/* Main content */}
					<section className="content">
						<div className="container-fluid">
							{/* Info boxes */}
							<div className="row">
								<div className="col-12 col-sm-6 col-md-3">
									<div className="info-box mb-3">
										<span className="info-box-icon bg-success elevation-1">
											<i className="fas fa-plus" />
										</span>
										<div className="info-box-content">
											<span className="info-box-text">Overall Profit</span>
											<span className="info-box-number">{`€ ${
												this.state?.infodash?.tProfit?.toFixed(2) || 0
											}`}</span>
										</div>
										{/* /.info-box-content */}
									</div>
									{/* /.info-box */}
								</div>

								<div className="col-12 col-sm-6 col-md-3">
									<div className="info-box mb-3">
										<span className="info-box-icon bg-danger elevation-1">
											<i className="fas fa-minus" />
										</span>
										<div className="info-box-content">
											<span className="info-box-text">Overall Cost</span>
											<span className="info-box-number">{`€ ${
												this.state?.infodash?.tCost?.toFixed(2) || 0
											}`}</span>
										</div>
									</div>
								</div>

								<div className="clearfix hidden-md-up" />

								<div className="col-12 col-sm-6 col-md-3">
									<div className="info-box mb-3">
										<span className="info-box-icon bg-warning elevation-1">
											<i className="fas fa-chart-bar" />
										</span>
										<div className="info-box-content">
											<span className="info-box-text">Overall Revenue</span>
											<span className="info-box-number">{`€ ${
												this.state?.infodash?.tRevenue?.toFixed(2) || 0
											}`}</span>
										</div>
										{/* /.info-box-content */}
									</div>
									{/* /.info-box */}
								</div>
								<div className="col-12 col-sm-6 col-md-3">
									<div className="info-box">
										<span className="info-box-icon bg-info elevation-1">
											<i className="fas fa-hashtag" />
										</span>
										<div className="info-box-content">
											<span className="info-box-text">Overall Rents</span>
											<span className="info-box-number">
												{`${this.state?.infodash?.tRents || 0}`}
											</span>
										</div>
									</div>
								</div>
							</div>
							{/* /.row */}
							<div className="row">
								<div className="col-md-12">
									<div className="card">
										<div className="card-header">
											<h5 className="card-title">
												Previous Month Recap Report
											</h5>
											<div className="card-tools">
												<button
													type="button"
													className="btn btn-tool"
													data-card-widget="collapse"
												>
													<i className="fas fa-minus" />
												</button>
											</div>
										</div>
										{/* ./card-body */}
										<div className="card-footer">
											<div className="row">
												<div className="col-sm-2 col-4">
													<div className="description-block border-right">
														<h5 className="description-header">
															{`€${
																this.state?.infodash?.mRevenue?.toFixed(
																	2
																) || 0
															}`}
														</h5>
														<span className="description-text">
															TOTAL REVENUE
														</span>
													</div>
													{/* /.description-block */}
												</div>
												{/* /.col */}
												<div className="col-sm-2 col-4">
													<div className="description-block border-right">
														<h5 className="description-header">
															{`€${
																this.state?.infodash?.mCost?.toFixed(
																	2
																) || 0
															}`}
														</h5>
														<span className="description-text">
															TOTAL COST
														</span>
													</div>
													{/* /.description-block */}
												</div>
												{/* /.col */}
												<div className="col-sm-2 col-4">
													<div className="description-block border-right">
														<h5 className="description-header">
															{`€${
																this.state?.infodash?.mProfit?.toFixed(
																	2
																) || 0
															}`}
														</h5>
														<span className="description-text">
															TOTAL PROFIT
														</span>
													</div>
													{/* /.description-block */}
												</div>
												{/* /.col */}
												<div className="col-sm-2 col-4">
													<div className="description-block border-right">
														<h5 className="description-header">{`${
															this.state?.infodash?.mRents || 0
														}`}</h5>
														<span className="description-text">
															TOTAL RENTS
														</span>
													</div>
													{/* /.description-block */}
												</div>
												<div className="col-sm-2 col-4">
													<div className="description-block">
														<h5 className="description-header">{`${
															this.state?.infodash?.mTime || 0
														} min`}</h5>
														<span className="description-text">
															TOTAL TIME
														</span>
													</div>
													{/* /.description-block */}
												</div>
												<div className="col-sm-2 col-4">
													<div className="description-block">
														<h5 className="description-header">{`${
															this.state?.infodash?.mMileage || 0
														} km`}</h5>
														<span className="description-text">
															TOTAL MILEAGE
														</span>
													</div>
													{/* /.description-block */}
												</div>
											</div>
											{/* /.row */}
										</div>
										{/* /.card-footer */}
									</div>
									{/* /.card */}
								</div>
								{/* /.col */}
							</div>
							{/* /.row */}
							{/* Main row */}

							<div className="row">
								{/* Left col */}
								<div className="col-md-12">
									{/* TABLE: LATEST ORDERS */}
									<div className="card">
										<div className="card-header border-transparent">
											<h3 className="card-title">Latest Events</h3>
											<div className="card-tools">
												<button
													type="button"
													className="btn btn-tool"
													data-card-widget="collapse"
												>
													<i className="fas fa-minus" />
												</button>
											</div>
										</div>
										{/* /.card-header */}
										<div className="card-body p-0">
											<div className="table-responsive">
												<table className="table m-0">
													<thead>
														<tr>
															<th>Event Type</th>
															<th>Message</th>
															<th>Status</th>
															<th>Timestamp</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<span>**</span>
															</td>
															<td>/</td>
															<td>
																<span className="badge badge-info">
																	None
																</span>
															</td>
															<td>
																<div
																	className="sparkbar"
																	data-color="#00c0ef"
																	data-height={20}
																>
																	/
																</div>
															</td>
														</tr>
														<tr>
															<td>
																<span>**</span>
															</td>
															<td>/</td>
															<td>
																<span className="badge badge-info">
																	None
																</span>
															</td>
															<td>
																<div
																	className="sparkbar"
																	data-color="#00c0ef"
																	data-height={20}
																>
																	/
																</div>
															</td>
														</tr>
														<tr>
															<td>
																<span>**</span>
															</td>
															<td>/</td>
															<td>
																<span className="badge badge-info">
																	None
																</span>
															</td>
															<td>
																<div
																	className="sparkbar"
																	data-color="#00c0ef"
																	data-height={20}
																>
																	/
																</div>
															</td>
														</tr>
														<tr>
															<td>
																<span>**</span>
															</td>
															<td>/</td>
															<td>
																<span className="badge badge-info">
																	None
																</span>
															</td>
															<td>
																<div
																	className="sparkbar"
																	data-color="#00c0ef"
																	data-height={20}
																>
																	/
																</div>
															</td>
														</tr>
														<tr>
															<td>
																<span>**</span>
															</td>
															<td>/</td>
															<td>
																<span className="badge badge-info">
																	None
																</span>
															</td>
															<td>
																<div
																	className="sparkbar"
																	data-color="#00c0ef"
																	data-height={20}
																>
																	/
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											{/* /.table-responsive */}
										</div>
									</div>
									{/* /.card */}
								</div>
								{/* /.col */}

								{/* /.col */}
							</div>

							{/* /.row */}
						</div>
						{/*/. container-fluid */}
					</section>
					{/* /.content */}
				</div>
				{/* /.content-wrapper */}
			</div>
		);
	}
}
