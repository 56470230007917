import { formatDate, formatDateDate, getBase64ImageFromURL } from '../../utils/utils';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export async function getPDFTravelOrder(booking, user?) {
	let images = [];
	if (booking?.returnImages?.length > 1) {
		for (const item of booking.returnImages) {
			images.push({
				image: await getBase64ImageFromURL(`${item}?w=100`),
				width: 100,
				margin: [15, 2],
			});
		}
	} else {
		if (booking.returnImage) {
			images.push({
				image: await getBase64ImageFromURL(`${booking.returnImage}?w=100`),
				width: 100,
				margin: [15, 2],
			});
		}
	}

	const PDF = {
		content: [
			booking?.ownerLogo && {
				image: await getBase64ImageFromURL(`${booking?.ownerLogo}?w=100`),
				width: 50,
			},
			{
				text: `${booking?.ownerName || ''}`,
			},
			{
				text: `${booking?.ownerAddress || ''}\n\n`,
				style: 'small',
			},
			{
				text: `Nalog za službeno potovanje\n\n`,
				style: 'header',
			},
			// BASIC DATA
			{
				columns: [
					{
						style: 'black',
						width: 100,
						text: [`Številka naloga : \n`, `Datum : \n`],
					},
					{
						text: [
							{
								text: `_______________________\n`,
								style: 'grey',
							},
							{
								text: booking?.to ? `${formatDateDate(booking?.to)}\n\n` : '',
								style: 'grey',
							},
						],
					},
				],
			},
			{
				text: `Odrejam da odpotuje\n`,
				style: 'smaller',
			},
			{
				columns: [
					{
						style: 'black',
						width: 100,
						text: [`Ime in priimek : \n`, `Delovno mesto : \n`],
					},
					{
						text: [
							{
								text: `${booking?.renter?.firstName} ${booking?.renter?.lastName}\n`,
								style: 'grey',
							},
							{
								text: `______________________________________________\n`,
								style: 'grey',
							},
						],
					},
				],
			},
			{
				text: `Prebivališče\n`,
				style: 'smaller',
			},
			{
				columns: [
					{
						style: 'black',
						width: 100,
						text: [`Naslov : \n`, `Poštna št. in Kraj : \n`],
					},
					{
						text: [
							{
								text: `______________________________________________\n`,
								style: 'grey',
							},
							{
								text: `______________________________________________\n\n`,
								style: 'grey',
							},
						],
					},
				],
			},

			{
				columns: [
					{
						style: 'black',
						width: 100,
						text: [`Odredil : \n`, `Namen : \n`],
					},
					{
						text: [
							{
								text: `______________________________________________\n`,
								style: 'grey',
							},
							{
								text: `${
									booking.travelPurpose ||
									'______________________________________________'
								}\n\n`,
								style: 'grey',
							},
						],
					},
				],
			},
			{
				columns: [
					{
						style: 'black',
						width: 100,
						text: [`Avans v znesku : \n`, `Izplačan dne : \n`],
					},
					{
						text: [
							{
								text: `_______________________ €\n`,
								style: 'grey',
							},
							{
								text: `_______________________\n\n`,
								style: 'grey',
							},
						],
					},
				],
			},
			{
				text: [
					{
						text: `Od kraja:   `,
						style: 'black',
					},
					{
						text: `${booking?.vehicle?.liveInfo?.geoName || ''}     `,
						style: 'grey',
					},
					{
						text: `Do kraja:   `,
						style: 'black',
					},
					{
						text: `${booking?.returnGeoName || ''}\n`,
						style: 'grey',
					},
				],
			},
			{
				columns: [
					{
						style: 'black',
						width: 100,
						text: [
							{
								text: `Razdalja `,
								style: 'black',
							},
						],
					},
					{
						text: [
							{
								text: `${booking?.mileageDone / 1000 || ''} km\n\n`,
								style: 'grey',
							},
						],
					},
				],
			},
			{
				columns: [
					{
						style: 'black',
						width: 100,
						text: [`Odhod : \n`, `Prihod : \n`, `Trajanje : \n`],
					},
					{
						text: [
							{
								text: `${booking?.from ? formatDate(booking?.from) : ''}\n`,
								style: 'grey',
							},
							{
								text: `${booking?.to ? formatDate(booking?.to) : ''}\n`,
								style: 'grey',
							},
							{
								text: `${booking?.endTime || ''} min\n`,
								style: 'grey',
							},
						],
					},
				],
			},
			{
				columns: [
					{
						style: 'black',
						width: 100,
						text: [`Vozilo : \n`, `Registrska št. : \n`, `Lastništvo : \n`],
					},
					{
						text: [
							{
								text: `${booking?.vehicle?.model || ''}\n`,
								style: 'grey',
							},
							{
								text: `${booking?.vehicle?.serialNumber || ''}\n`,
								style: 'grey',
							},
							{
								text: `_______________________\n\n`,
								style: 'grey',
							},
						],
					},
				],
			},
			{
				text: `Stroške plača\n`,
				style: 'smaller',
			},
			{
				columns: [
					{
						style: 'black',
						width: 100,
						text: [`Naziv : \n`, `Naslov : \n`, `Poštna št. in Kraj : \n`],
					},
					{
						text: [
							{
								text: `______________________________________________\n`,
								style: 'grey',
							},
							{
								text: `______________________________________________\n`,
								style: 'grey',
							},
							{
								text: `______________________________________________\n`,
								style: 'grey',
							},
						],
					},
				],
			},
			// TABLE1
			{
				style: 'tableExample',
				layout: 'noBorders',
				table: {
					widths: [100, '*', '*'],
					body: [
						[
							{
								text: 'Nalog pripravil :',
								style: 'tableSmaller',
							},
							{
								text: '___________________________________',
							},

							{
								text: `(podpis)`,
								style: 'tableSmall',
							},
						],
						[
							{
								text: `Nalog odobril :`,
								style: 'tableSmaller',
							},
							{
								text: '___________________________________',
							},
							{
								text: `(podpis)`,
								style: 'tableSmall',
							},
						],
						[
							{
								text: `Uporabnik vozila :`,
								style: 'tableSmaller',
							},
							{
								text: '___________________________________',
							},
							{
								text: `(podpis)`,
								style: 'tableSmall',
							},
						],
					],
				},
			},
		],
		defaultStyle: {
			fontSize: 12,
			lineHeight: 1.3,
		},
		styles: {
			header: {
				fontSize: 20,
				bold: true,
				alignment: 'center',
			},
			subheader: {
				fontSize: 15,
				bold: true,
			},
			quote: {
				italics: true,
			},
			black: {
				fontSize: 12,
				bold: true,
			},
			grey: {
				fontSize: 12,
				bold: true,
				color: 'grey',
			},
			small: {
				fontSize: 8,
			},
			tableSmall: { alignment: 'left', fontSize: 8 },
			smaller: {
				fontSize: 10,
			},
			tableSmaller: { alignment: 'left', fontSize: 10 },
			tableExample: {
				margin: [0, 20, 0, 0],
				lineHeight: 1.2,
			},
			tableHeader: {
				alignment: 'center',
				bold: true,
				fontSize: 13,
				fillColor: '#a0979c',
				color: 'white',
			},
		},
	};

	pdfMake.createPdf(PDF).open();
}
