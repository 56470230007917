import axios from 'axios';
import { getAuthHeaders } from './utils';

let API_URL = process.env.REACT_APP_API_HOST;

export function fetchCars(query) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/vehicles/${query}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchCar(carId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/vehicles/${carId}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchCarsCount(userId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/users/${userId}/vehicles/count`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function deleteCar(carId) {
	return new Promise((resolve, reject) => {
		axios.delete(`${API_URL}/vehicles/${carId}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function serviceCar(carId, payload) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/vehicles/${carId}/service`, payload, getAuthHeaders())
			.then((res) => {
				resolve(res.data);
			});
	});
}

export function geofenceCountryAdd(carId, geoId) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/vehicles/${carId}/geof/${geoId}`, {}, getAuthHeaders())
			.then((res) => {
				resolve(res.data);
			});
	});
}

export function geofenceCountryRemove(carId, geoId) {
	return new Promise((resolve, reject) => {
		axios.delete(`${API_URL}/vehicles/${carId}/geof/${geoId}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function transportVehicleON(carId) {
	return new Promise((resolve, reject) => {
		axios.post(`${API_URL}/vehicles/${carId}/manTransON`, {}, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function transportVehicleOFF(carId) {
	return new Promise((resolve, reject) => {
		axios.post(`${API_URL}/vehicles/${carId}/manTransOFF`, {}, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function carDoorUnlock(carId) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/vehicles/${carId}/car/unlockDoor`, {}, getAuthHeaders())
			.then((res) => {
				resolve(res.data);
			});
	});
}

export function carDoorLock(carId) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/vehicles/${carId}/car/lockDoor`, {}, getAuthHeaders())
			.then((res) => {
				resolve(res.data);
			});
	});
}

export function carEngineDisable(carId) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/vehicles/${carId}/car/imobilizeOn`, {}, getAuthHeaders())
			.then((res) => {
				resolve(res.data);
			});
	});
}

export function carEngineEnable(carId) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/vehicles/${carId}/car/imobilizeOff`, {}, getAuthHeaders())
			.then((res) => {
				resolve(res.data);
			});
	});
}

export function resetIoT(carId) {
	return new Promise((resolve, reject) => {
		axios.post(`${API_URL}/vehicles/${carId}/manIoTReset`, {}, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function releaseBTLock(carId) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/vehicles/${carId}/manBTLRelease`, {}, getAuthHeaders())
			.then((res) => {
				resolve(res.data);
			});
	});
}

export function resetBTLock(carId) {
	return new Promise((resolve, reject) => {
		axios.post(`${API_URL}/vehicles/${carId}/manBTLReset`, {}, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function lookForVehicle(carId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/vehicles/${carId}/lookFor/light`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function beepForVehicle(carId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/vehicles/${carId}/lookFor/beep`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function lastRentVehicle(carId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/vehicles/${carId}/lastRents`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchUserCars(query, userId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/users/${userId}/vehicles/${query}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function patchUserCar(carId, userId, payload) {
	return new Promise((resolve, reject) => {
		axios
			.patch(`${API_URL}/users/${userId}/vehicles/${carId}`, payload, getAuthHeaders())
			.then((res) => {
				resolve(res.data);
			});
	});
}
