import axios from 'axios';
import { getAuthHeaders } from './utils';

let API_URL = process.env.REACT_APP_API_HOST;

export function fetchUsers(query) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/users${query}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchUser(userId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/users/${userId}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function updateUser(userId, payload) {
	return new Promise((resolve, reject) => {
		axios.patch(`${API_URL}/users/${userId}`, payload, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchUsersCount() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/users/count`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchUsersDash(userId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/users/${userId}/infodash`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchUserCars(userId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/users/${userId}/cars`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function patchUserCar(carId, userId, payload) {
	return new Promise((resolve, reject) => {
		axios
			.patch(`${API_URL}/users/${userId}/cars/${carId}`, payload, getAuthHeaders())
			.then((res) => {
				resolve(res.data);
			});
	});
}

export function patchUser(userId, payload) {
	return new Promise((resolve, reject) => {
		axios.patch(`${API_URL}/users/${userId}`, payload, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function sendGift(userId, payload) {
	return new Promise((resolve, reject) => {
		axios.post(`${API_URL}/users/${userId}/gift`, payload, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}
