import axios from 'axios';
import { getAuthHeaders } from './utils';

let API_URL = process.env.REACT_APP_API_HOST;

export function fetchScheduledEvents(query) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/rent/${query}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function createScheduledEvent(carId, payload) {
	return new Promise((resolve, reject) => {
		axios.post(`${API_URL}/rent/${carId}/schedule`, payload, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function patchScheduledEvent(scheduleId, payload) {
	return new Promise((resolve, reject) => {
		axios
			.patch(`${API_URL}/rent/${scheduleId}/schedule`, payload, getAuthHeaders())
			.then((res) => {
				resolve(res.data);
			});
	});
}

export function deleteScheduledEvent(scheduleId) {
	return new Promise((resolve, reject) => {
		axios.delete(`${API_URL}/rent/${scheduleId}/schedule`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchBookings(query) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/rent${query}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchBooking(bookingId) {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/rent/${bookingId}`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}

export function fetchBookingsCount() {
	return new Promise((resolve, reject) => {
		axios.get(`${API_URL}/rent/count`, getAuthHeaders()).then((res) => {
			resolve(res.data);
		});
	});
}
