import React, { Component } from 'react';
import ReportsTable from './reportsTable';

export default class Reports extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchFilter: {},
		};
	}

	componentDidMount() {}

	render() {
		return (
			<div>
				<div className="content-wrapper">
					<section className="content">
						<div className="container-fluid">
							<div className="col-sm-6">
								<h2 className="m-0 text-dark">Reports List</h2>
							</div>
							<ReportsTable />
						</div>
					</section>
				</div>
			</div>
		);
	}
}
