import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './protectedRoute';
import { ToastContainer } from 'react-toastify';
import Login from '../global/login';
import Header from '../global/header';
import Footer from '../global/footer';
import Content from '../global/content';
import Sidebar from '../global/sidebar';
import Cars from '../vehicles/cars';
import Users from '../users/users';
import Map from '../map/map';
import Schedule from '../schedule/schedule';
import Bookings from '../rents/bookings';
import Reports from '../reports/reports';

export default class AppRouter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: JSON.parse(localStorage.getItem('user')),
		};
	}

	componentDidMount() {}
	render() {
		return (
			<div>
				<ToastContainer autoClose={3000} hideProgressBar={true} />
				<Router>
					<Switch>
						<Route exact path="/" component={Login} />
						<ProtectedLayout exact path="/pnl/dash" component={Content} />
						{this.state.currentUser?.role &&
							_.indexOf(
								['ROOT', 'ADMIN', 'SUPPORT', 'MANAGER', 'SERVICE'],
								this.state.currentUser?.role
							) > -1 && (
								<ProtectedLayout exact path="/pnl/vehicles" component={Cars} />
							)}

						{this.state.currentUser?.role &&
							_.indexOf(
								['ROOT', 'ADMIN', 'SUPPORT', 'MANAGER', 'SERVICE'],
								this.state.currentUser?.role
							) > -1 && <ProtectedLayout exact path="/pnl/map" component={Map} />}
						{this.state.currentUser?.role &&
							_.indexOf(['ROOT', 'ADMIN', 'MANAGER'], this.state.currentUser?.role) >
								-1 && (
								<ProtectedLayout exact path="/pnl/schedule" component={Schedule} />
							)}
						{this.state.currentUser?.role &&
							_.indexOf(
								['ROOT', 'ADMIN', 'SUPPORT', 'MANAGER'],
								this.state.currentUser?.role
							) > -1 && (
								<ProtectedLayout exact path="/pnl/rents" component={Bookings} />
							)}

						{this.state.currentUser?.role &&
							_.indexOf(
								['ROOT', 'ADMIN', 'SUPPORT', 'MANAGER', 'SERVICE'],
								this.state.currentUser?.role
							) > -1 && (
								<ProtectedLayout exact path="/pnl/reports" component={Reports} />
							)}

						{this.state.currentUser?.role &&
							_.indexOf(['ROOT', 'ADMIN', 'SUPPORT'], this.state.currentUser?.role) >
								-1 && <ProtectedLayout exact path="/pnl/users" component={Users} />}

						<Route component={NoMatch} />
					</Switch>
				</Router>
			</div>
		);
	}
}

// eslint-disable-next-line react/prop-types
const ProtectedLayout = ({ component: Component, ...rest }) => {
	return (
		<ProtectedRoute
			{...rest}
			isPublic={false}
			component={(matchProps) => (
				<div>
					<Header />
					<Sidebar />
					<Component {...matchProps} />
					<Footer />
				</div>
			)}
		/>
	);
};

const NoMatch = () => (
	<div className="container">
		<h1>Contact support for access grants!</h1>
	</div>
);
