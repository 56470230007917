/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { fetchBooking } from '../../services/bookings';
import { formatDate } from '../../utils/utils';
import { getPDFTravelOrder } from '../../utils/pdfs/printTravelOrder';
import { getPDFTravelReport } from '../../utils/pdfs/printReport';

const currentUser = JSON.parse(localStorage.getItem('user'));

export default class BookingModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			booking: null,
			showBeforeImages: false,
			showAfterImages: false,
		};

		this.bookingId = props?.bookingId;
	}

	componentDidMount() {
		Promise.all([fetchBooking(this.bookingId)]).then((res) => {
			this.setState({ booking: res[0] });
		});
	}

	selectUser(userId) {
		this.props.history.push(`/pnl/users/${userId}`);
	}

	openImage(link) {
		if (!link) return;
		window.open(link, '_blank');
	}

	openPDF(base64PDF) {
		if (!base64PDF) return;
		let pdfWindow = window.open('');
		pdfWindow.document.write(
			"<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
				encodeURI(base64PDF) +
				"'></iframe>"
		);
	}

	createPDF(booking, type) {
		if (type === 'REPORT') {
			getPDFTravelReport(booking);
		} else if (type === 'TRAVEL') {
			getPDFTravelOrder(booking);
		} else {
			return;
		}
	}

	render() {
		const location1 = {
			color: 'green',
			textDecoration: 'underline',
			fontWeight: 'bolder',
		};

		const location2 = {
			textDecoration: 'underline',
			fontWeight: 'bolder',
			color: 'purple',
		};

		const lastPhoto = {
			textDecoration: 'underline',
			fontWeight: 'bolder',
			color: '#dd9613',
		};

		let booking = this.state.booking;

		return (
			<div>
				{booking && (
					<div className="card card-light card-outline card-tabs">
						<div className="card-header p-0 pt-1 border-bottom-0">
							<ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
								<li className="nav-item">
									<a
										className="nav-link active"
										id="custom-tabs-three-1-tab"
										data-toggle="pill"
										href="#custom-tabs-three-1"
										role="tab"
										aria-controls="custom-tabs-three-1"
										aria-selected="true"
									>
										Basic
									</a>
								</li>
								<li className="nav-item">
									<a
										className="nav-link"
										id="custom-tabs-three-2-tab"
										data-toggle="pill"
										href="#custom-tabs-three-2"
										role="tab"
										aria-controls="custom-tabs-three-2"
										aria-selected="false"
									>
										Advanced
									</a>
								</li>
							</ul>
							<div className="card-body">
								<div className="tab-content" id="custom-tabs-three-tabContent">
									<div
										className="tab-pane fade active show"
										id="custom-tabs-three-1"
										role="tabpanel"
										aria-labelledby="custom-tabs-three-1-tab"
									>
										<div className="row">
											<div className="col-12 col-sm-3">
												<div className="info-box bg-success">
													<span className="info-box-icon">
														<i className="fas fa-coins" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Earnings
														</span>

														<span className="info-box-number">
															{`${booking?.endPrice.toFixed(2)} `}
															<small>€</small>
														</span>
													</div>
												</div>
											</div>

											<div className="col-12 col-sm-3">
												<div className="info-box bg-dark">
													<span className="info-box-icon">
														<i className="fas fa-road" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Mileage Done
														</span>
														<span className="info-box-number">
															{`${
																booking.mileageDone / 1000 || 0
															} km`}
														</span>
													</div>
												</div>
											</div>

											<div className="col-12 col-sm-3">
												<div className="info-box bg-lightblue">
													<span className="info-box-icon">
														<i className="fas fa-hourglass-start" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Rent Start
														</span>
														<span className="info-box-number">
															{formatDate(booking.from)}
														</span>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-lightblue">
													<span className="info-box-icon">
														<i className="fas fa-hourglass-end" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Rent End
														</span>
														<span className="info-box-number">
															{formatDate(booking.to)}
														</span>
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-12 col-sm-3">
												<div className="info-box bg-navy">
													<span className="info-box-icon">
														<i className="fas fa-clock" />
													</span>
													<div className="info-box-content">
														<span className="info-box-text">
															Duration
														</span>
														<span className="info-box-number">
															{`${booking.endTime} min`}
														</span>
													</div>
												</div>
											</div>

											<div className="col-12 col-sm-3">
												<div className="info-box bg-olive">
													<span className="info-box-icon">
														{booking?.vehicle?.fuelCapacity < 0 ? (
															<i className="fas fa-charging-station" />
														) : (
															<i className="fas fa-gas-pump" />
														)}
													</span>
													{booking?.vehicle?.fuelCapacity < 0 ? (
														<div className="info-box-content">
															<span className="info-box-text">
																Battery Used
															</span>
															<span className="info-box-number">
																{`${booking.fuelDiff || 0} %`}
															</span>
														</div>
													) : (
														<div className="info-box-content">
															<span className="info-box-text">
																Fuel Used
															</span>
															<span className="info-box-number">
																{`${
																	booking.fuelDiff || 0
																} L (liters)`}
															</span>
														</div>
													)}
												</div>
											</div>

											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																Start Location
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																<a
																	style={location1}
																	target="_blank"
																	rel="noopener noreferrer"
																	href={
																		booking?.vehicle?.liveInfo
																			?.locationLink
																	}
																>
																	Show On Map
																</a>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-sm-3">
												<div className="info-box bg-light">
													<div className="info-box-content">
														<div>
															<span className="info-box-text text-center text-muted">
																End Location
															</span>
															<span className="info-box-number text-center text-muted mb-0">
																<a
																	style={location1}
																	target="_blank"
																	rel="noopener noreferrer"
																	href={
																		booking?.vehicle
																			?.returnLocation
																	}
																>
																	Show On Map
																</a>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="card card-teal collapsed-card">
													<div
														className="card-header"
														data-card-widget="collapse"
													>
														<h3 className="card-title">
															<i
																className="fas fa-info-circle fa-lg"
																style={{
																	marginRight: '10px',
																}}
															/>
															<strong>{` ${booking.vehicle.type} INFO`}</strong>
														</h3>
														<div className="card-tools">
															<button
																type="button"
																className="btn btn-tool"
																data-card-widget="collapse"
															>
																<i className="fas fa-plus" />
															</button>
														</div>
													</div>

													<div className="card-body">
														<div className="form-group">
															<div className="row">
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<span className="info-box-icon">
																			<i className="fas fa-qrcode" />
																		</span>
																		<div className="info-box-content">
																			<span className="info-box-text">
																				QR Code
																			</span>
																			<span className="info-box-number">
																				{`${booking?.vehicle?.IMEI}`}
																			</span>
																		</div>
																	</div>
																</div>

																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<span className="info-box-icon">
																			<i className="fas fa-user-tie" />
																		</span>
																		<div className="info-box-content">
																			<span className="info-box-text">
																				Owner
																			</span>

																			<span className="info-box-number">
																				{`${
																					booking?.vehicle
																						?.ownerName ||
																					'/'
																				}`}
																			</span>
																		</div>
																	</div>
																</div>

																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<span className="info-box-icon">
																			<i className="fas fa-clock" />
																		</span>
																		<div className="info-box-content">
																			<span className="info-box-text">
																				Price Per Minute
																			</span>
																			<span className="info-box-number">
																				{`${booking?.vehicle?.pricing?.pricePerMinute} €`}
																			</span>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<span className="info-box-icon">
																			<i className="fas fa-lock" />
																		</span>
																		<div className="info-box-content">
																			<span className="info-box-text">
																				Start Price
																			</span>
																			<span className="info-box-number">
																				{`${booking?.vehicle?.pricing?.startingFee} €`}
																			</span>
																		</div>
																	</div>
																</div>
															</div>

															<div className="row">
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<span className="info-box-text text-center text-muted">
																				Serial Number
																			</span>
																			<span className="info-box-number text-center text-muted mb-0">
																				{`${booking.vehicle.serialNumber}`}
																			</span>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<span className="info-box-text text-center text-muted">
																				IoT IMEI
																			</span>
																			<span className="info-box-number text-center text-muted mb-0">
																				{`${booking?.vehicle?.trackerInfo?.trackerIMEI}`}
																			</span>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<span className="info-box-text text-center text-muted">
																				IoT SIM
																			</span>
																			<span className="info-box-number text-center text-muted mb-0">
																				{`${booking?.vehicle?.trackerInfo?.trackerPhone}`}
																			</span>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<span className="info-box-text text-center text-muted">
																				Bluetooth Lock IMEI
																			</span>
																			<span className="info-box-number text-center text-muted mb-0">
																				{`${
																					booking?.vehicle
																						?.trackerInfo
																						?.btlockIMEI ||
																					'/'
																				}`}
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="tab-pane fade"
										id="custom-tabs-three-2"
										role="tabpanel"
										aria-labelledby="custom-tabs-three-2-tab"
									>
										<div className="row">
											<div className="col-md-8">
												<div className="card card-info collapsed-card">
													<div
														className="card-header"
														data-card-widget="collapse"
													>
														<h3 className="card-title">
															<i
																className="fas fa-history fa-lg"
																style={{
																	marginRight: '10px',
																}}
															/>
															<strong>{` Rent History `}</strong>
														</h3>
														<div className="card-tools">
															<button
																type="button"
																className="btn btn-tool"
																data-card-widget="collapse"
															>
																<i className="fas fa-plus" />
															</button>
														</div>
													</div>

													<div className="card-body">
														<div className="form-group">
															<div className="row">
																<div className="col-12 col-sm-6">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<div>
																				<span className="info-box-text text-center text-muted">
																					{`Last Renter`}
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					{currentUser?.role &&
																					_.indexOf(
																						[
																							'ROOT',
																							'ADMIN',
																							'SUPPORT',
																						],
																						currentUser?.role
																					) > -1
																						? `${
																								booking
																									?.renter
																									?.firstName ||
																								'/'
																						  } ${
																								booking
																									?.renter
																									?.lastName ||
																								'/'
																						  }`
																						: (booking
																								?.renter
																								?.firstName
																								? `${
																										booking?.renter?.firstName.charAt(
																											0
																										) +
																										'*****'
																								  }`
																								: '/') +
																						  '  ' +
																						  (booking
																								?.renter
																								?.lastName
																								? `${
																										booking?.renter?.lastName.charAt(
																											0
																										) +
																										'*****'
																								  }`
																								: '/')}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<div>
																				<span className="info-box-text text-center text-muted">
																					Rent End Photo
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					{booking?.returnImage !==
																					'BALANCE_LOW' ? (
																						<a
																							style={
																								lastPhoto
																							}
																							target="_blank"
																							rel="noopener noreferrer"
																							href={
																								booking.returnImage
																							}
																						>
																							Show
																							Photo
																						</a>
																					) : (
																						'Automatic End (balance low)'
																					)}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-3">
																	<div className="info-box bg-light">
																		<div className="info-box-content">
																			<div>
																				<span className="info-box-text text-center text-muted">
																					Rent End
																					Location
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					<a
																						style={
																							location2
																						}
																						target="_blank"
																						rel="noopener noreferrer"
																						href={
																							booking?.returnLocation
																						}
																					>
																						Show On Map
																					</a>
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>

															<div className="row">
																{currentUser?.role &&
																_.indexOf(
																	['ROOT', 'ADMIN', 'SUPPORT'],
																	currentUser?.role
																) > -1 ? (
																	<div className="col-12 col-sm-6">
																		<div className="info-box bg-light">
																			<div className="info-box-content">
																				<div>
																					<span className="info-box-text text-center text-muted">
																						{`${
																							booking
																								?.renter
																								?.email ||
																							'/'
																						}`}
																					</span>
																					<span className="info-box-number text-center text-muted mb-0">
																						{`${
																							booking
																								?.renter
																								?.phone ||
																							'/'
																						}`}
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																) : (
																	<div className="col-12 col-sm-6">
																		<div className="info-box bg-light">
																			<div className="info-box-content">
																				<span className="info-box-text text-center text-muted">
																					Review Message
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					{`${
																						booking
																							?.review
																							?.message ||
																						'/'
																					}`}
																				</span>
																			</div>
																		</div>
																	</div>
																)}
																<>
																	<div className="col-12 col-sm-3">
																		<div className="info-box bg-light">
																			<div className="info-box-content">
																				<span className="info-box-text text-center text-muted">
																					Review Stars
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					{`${
																						booking
																							?.review
																							?.stars ||
																						'/'
																					}`}
																				</span>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-3">
																		<div className="info-box bg-light">
																			<div className="info-box-content">
																				<span className="info-box-text text-center text-muted">
																					Rent End Area
																				</span>
																				<span className="info-box-number text-center text-muted mb-0">
																					{`${booking.returnGeoName}`}
																				</span>
																			</div>
																		</div>
																	</div>
																</>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-4">
												<div className="card card-danger collapsed-card">
													<div
														className="card-header"
														data-card-widget="collapse"
													>
														<h3 className="card-title">
															<i
																className="fas fa-file-alt fa-lg"
																style={{
																	marginRight: '10px',
																}}
															/>
															<strong>{` View Documents `}</strong>
														</h3>
														<div className="card-tools">
															<button
																type="button"
																className="btn btn-tool"
																data-card-widget="collapse"
															>
																<i className="fas fa-plus" />
															</button>
														</div>
													</div>

													<div className="card-body">
														<div className="form-group">
															<div className="row">
																<div className="info-box">
																	<div className="info-box-content">
																		{booking?.status !==
																		'DRIVING' ? (
																			<div className="user-data">
																				<button
																					onClick={() =>
																						this.createPDF(
																							booking,
																							'TRAVEL'
																						)
																					}
																					className="btn btn-secondary save-btn"
																					type="button"
																				>
																					Open Travel
																					Order
																				</button>
																			</div>
																		) : (
																			<span>
																				Rent Unfinished
																			</span>
																		)}
																	</div>
																	<span className="info-box-icon bg-warning elevation-1">
																		<i className="fas fa-file-contract" />
																	</span>
																</div>
															</div>
															<div className="row">
																<div className="info-box">
																	<div className="info-box-content">
																		{booking?.status !==
																		'DRIVING' ? (
																			<div className="user-data">
																				<button
																					onClick={() =>
																						this.createPDF(
																							booking,
																							'REPORT'
																						)
																					}
																					className="btn btn-secondary save-btn"
																					type="button"
																				>
																					Open Rent Report
																				</button>
																			</div>
																		) : (
																			<span>
																				Rent Unfinished
																			</span>
																		)}
																	</div>
																	<span className="info-box-icon bg-warning elevation-1">
																		<i className="fas fa-file-pdf" />
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
